const toString = Object.prototype.toString

/**
 * Returns type specified in constructor of object param
 * @param {*} o
 * @returns {string}
 */
export const getType = o => {
  const name = o?.constructor?.name
  if (name) return name
  // eslint-disable-next-line no-useless-escape
  return toString.call(o).split(/[\[ \]]/)[2] || 'Unknown'
}

/**
 * Returns type and value of data provided in a string
 * @param {*} obj The thing you want to represent with a string
 * @param {?boolean} [compact=false] Only give key count for Object, if true
 * @returns {string}
 */
export const repr = (obj, compact = false) => {
  // eslint-disable-next-line no-self-compare
  if (obj !== obj) return String(obj)
  const type = getType(obj)
  if (obj == null) return type

  if (Array.isArray(obj)) {
    const { 0: first } = obj
    const arrayType = first ? getType(first) : 'Unknown'
    return `${type}(${obj.length})<${arrayType}>`
  }

  if (type === 'Map' || type === 'Set') {
    const first = obj.entries().next()
    if (first.value) {
      const [key, value] = first.value
      return `${type}(${obj.size})<${getType(key)}${type === 'Map' ? `, ${getType(value)}` : ''}>`
    }
    return `${type}(${obj.size})`
  }

  const keys = type === 'Object' ? Object.keys(obj) : null
  return `${type}(${
    type === 'Object' ? `{ ${compact ? keys.length : keys.join(', ')} }` : String(obj)
  })`
}
