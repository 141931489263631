export const EMPTY_ARRAY = Object.freeze([])
export const EMPTY_OBJECT = Object.freeze({})

export const BREAKPOINTS = Object.freeze(['xs', 'sm', 'md', 'lg', 'xl'])

export const GOLDEN_RATIO = (1 + Math.sqrt(5)) / 2

const divideByGoldenRatio = num => num / GOLDEN_RATIO
const getFractionalGoldenth = (num, fraction) => {
  const nextGoldenth = divideByGoldenRatio(num)
  return num - ((num - nextGoldenth) * fraction)
}
export const goldenth = (factor = 1) => Array.from({ length: Math.ceil(factor) }).reduce((acc, _, i) => (
  factor >= (i + 1)
    ? divideByGoldenRatio(acc)
    : getFractionalGoldenth(acc, (factor - i))
), 1)
