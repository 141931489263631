/* eslint-disable no-restricted-globals */
export default () => {
  // the only reliable means to get the global object is
  // `Function('return this')()`
  // However, this causes CSP violations in Chrome apps.
  if (typeof self !== 'undefined') { return self }
  if (typeof window !== 'undefined') { return window }
  if (typeof global !== 'undefined') { return global }
  // eslint-disable-next-line no-undef
  if (typeof globalThis !== 'undefined') { return globalThis }
  return null
}
