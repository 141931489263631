import { minutes } from 'milliseconds'
import nanoMemoize from 'nano-memoize'

import { shallowEquals } from './equality'

export const defaultMemoizerOpts = {
  depth: 2,
  maxAge: minutes(60),
}
const memoize = (fn, opts) => nanoMemoize(fn, { ...defaultMemoizerOpts, ...opts })

export const shallowEqualsMemoizer = (fn, options = defaultMemoizerOpts) => {
  const { depth, ...opts } = options
  return nanoMemoize(fn, {
    ...defaultMemoizerOpts,
    ...opts,
    equals: (left, right) => shallowEquals(left, right, depth),
  })
}
export default memoize
